import { Injectable } from '@angular/core'

import { Alert } from '../domain/alert.model'
import { AlertParam } from '../domain/alert-param.model'
import { AlertConfiguration } from '../domain/alert-configuration.model'
import { AlertsFacadeBase } from '../strategies/alerts-facade-base'
import { alertsActions } from '../+state/alerts.actions'

@Injectable()
export class StoreAlertsFacadeStrategy extends AlertsFacadeBase {
  defaultDuration = 5000
  override addSuccess(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    this.store.dispatch(
      alertsActions.add({
        alert: {
          id: Date.now(),
          ...alert,
          type: 'success'
        },
        configuration: {
          duration: this.defaultDuration,
          isDismisable: true,
          isAutoClose: true,
          isProgress: false,
          ...extraConfiguration
        }
      })
    )
  }
  override addError(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    console.error(alert)
    this.store.dispatch(
      alertsActions.add({
        alert: {
          id: Date.now(),
          ...alert,
          type: 'error'
        },
        configuration: {
          duration: this.defaultDuration,
          isDismisable: true,
          isAutoClose: false,
          isProgress: false,
          ...extraConfiguration
        }
      })
    )
  }
  override addInfo(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    this.store.dispatch(
      alertsActions.add({
        alert: {
          id: Date.now(),
          ...alert,
          type: 'info'
        },
        configuration: {
          duration: this.defaultDuration,
          isDismisable: true,
          isAutoClose: true,
          isProgress: false,
          ...extraConfiguration
        }
      })
    )
  }
  override addWarning(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    this.store.dispatch(
      alertsActions.add({
        alert: {
          id: Date.now(),
          ...alert,
          type: 'warning'
        },
        configuration: {
          duration: this.defaultDuration,
          isDismisable: true,
          isAutoClose: true,
          isProgress: false,
          ...extraConfiguration
        }
      })
    )
  }
  override remove(id: Alert['id']): void {
    console.log('remove', id)
    this.store.dispatch(alertsActions.remove({ id }))
  }
}
