import { Alert, AlertToastProps } from './alert.model'

export const KnownAlertProps: Record<Alert['type'], AlertToastProps> = {
  success: {
    iconName: 'heroCheckCircle',
    backgroundClassName: 'bg-emerald-600'
  },
  info: {
    iconName: 'heroInformationCircle',
    backgroundClassName: 'bg-blue-600'
  },
  error: {
    iconName: 'heroExclamationTriangle',
    backgroundClassName: 'bg-red-600'
  },
  warning: {
    iconName: 'heroInformationCircle',
    backgroundClassName: 'bg-warning'
  }
}
