import { Injectable } from '@angular/core'

import { AlertTypeEnum } from '../domain/alerts-legacy.models'
import { AlertParam } from '../domain/alert-param.model'
import { AlertConfiguration } from '../domain/alert-configuration.model'
import { addAlert, removeAlert } from '../+state/alerts.actions'
import { Alert } from '../domain/alert.model'
import { AlertsFacadeBase } from './alerts-facade-base'
import { asapScheduler } from 'rxjs'
const DEFAULT_DURATION = 5000
@Injectable()
export class LegacyAlertsFacadeStrategy extends AlertsFacadeBase {
  override addSuccess(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        addAlert({
          label: alert.message,
          alertType: AlertTypeEnum.Success,
          id: alert.id ?? Date.now(),
          duration: extraConfiguration?.duration ?? DEFAULT_DURATION,
          autoClose: extraConfiguration?.isAutoClose ?? true,
          displaySpinner: extraConfiguration?.isProgress ?? false,
          canClose: extraConfiguration?.isDismisable ?? true
        })
      )
    )
  }
  override addError(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    console.error("Toast error", alert, new Error().stack)

    asapScheduler.schedule(() =>
      this.store.dispatch(
        addAlert({
          label: alert.message,
          alertType: AlertTypeEnum.Danger,
          id: alert.id ?? Date.now(),
          duration: extraConfiguration?.duration ?? DEFAULT_DURATION,
          autoClose: extraConfiguration?.isAutoClose ?? true,
          displaySpinner: extraConfiguration?.isProgress ?? false,
          canClose: extraConfiguration?.isDismisable ?? true
        })
      )
    )
  }
  override addInfo(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        addAlert({
          label: alert.message,
          alertType: AlertTypeEnum.Information,
          id: alert.id ?? Date.now(),
          duration: extraConfiguration?.duration ?? 5000,
          autoClose: extraConfiguration?.isAutoClose ?? true,
          displaySpinner: extraConfiguration?.isProgress ?? false,
          canClose: extraConfiguration?.isDismisable ?? true
        })
      )
    )
  }
  override addWarning(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        addAlert({
          label: alert.message,
          alertType: AlertTypeEnum.Information,
          id: alert.id ?? Date.now(),
          duration: extraConfiguration?.duration ?? DEFAULT_DURATION,
          autoClose: extraConfiguration?.isAutoClose ?? true,
          displaySpinner: extraConfiguration?.isProgress ?? false,
          canClose: extraConfiguration?.isDismisable ?? true
        })
      )
    )
  }

  override remove(id: Alert['id']): void {
    asapScheduler.schedule(() =>
      this.store.dispatch(
        removeAlert({
          id
        })
      )
    )
  }
}
