import { Alert, AlertToast } from './alert.model'

export enum AlertTypeEnum {
  Success = 'success',
  Information = 'information',
  Danger = 'danger'
}
export const AlerTypeMap: Record<string, { icon: string; bgColor: string }> = {
  [AlertTypeEnum.Success]: {
    icon: 'heroCheckCircle',
    bgColor: 'bg-emerald-600'
  },
  [AlertTypeEnum.Information]: {
    icon: 'heroInformationCircle',
    bgColor: 'bg-blue-600'
  },
  [AlertTypeEnum.Danger]: {
    icon: 'heroExclamationTriangle',
    bgColor: 'bg-red-600'
  }
}

export const BackwardCompatibilityTypes: Record<
  keyof typeof AlertTypeEnum,
  Alert['type']
> = {
  Success: 'success',
  Information: 'info',
  Danger: 'error'
}

export type AlertType = `${AlertTypeEnum}`

export interface IAlertParam {
  label: string
  alertType: AlertType
  id?: number | string
  duration?: number
  autoClose?: boolean
  canClose?: boolean
  displaySpinner?: boolean
  customClass?: string
}

const getManuallyCloseValue = (
  value: IAlertParam['autoClose'],
  type: IAlertParam['alertType']
): boolean => {
  if (value === undefined) return true
  if (type === AlertTypeEnum.Danger) return false
  return value
}

export class LegacyAlert {
  public label = ''
  public icon = ''
  public bgColor = ''
  public id: number | string = new Date().getTime()
  public duration = 5000
  public type = ''
  public autoClose = true
  public canClose = true
  public displaySpinner = true
  public customClass = ''

  constructor(raw: IAlertParam) {
    if (!raw) return
    this.label = raw.label
    this.icon = AlerTypeMap[raw.alertType].icon
    this.bgColor = AlerTypeMap[raw.alertType].bgColor
    this.id = raw.id || this.id
    this.duration = raw.duration || this.duration
    this.type = raw.alertType
    this.autoClose = getManuallyCloseValue(raw.autoClose, raw.alertType)
    this.canClose = raw.canClose === undefined ? true : raw.canClose
    this.displaySpinner =
      raw.displaySpinner === undefined ? false : raw.displaySpinner
    this.customClass = raw.customClass || ''
  }

  toNewAlert(): AlertToast {
    return {
      id: this.id,
      backgroundClassName: this.bgColor,
      iconName: this.icon,
      message: this.label,
      type: BackwardCompatibilityTypes[this.type as keyof typeof AlertTypeEnum],
      duration: this.duration,
      isDismisable: this.canClose,
      isAutoClose: this.autoClose,
      isProgress: this.displaySpinner
    }
  }
}
