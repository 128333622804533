import { Injectable } from '@angular/core'
import { AlertParam } from '../domain/alert-param.model'
import { AlertConfiguration } from '../domain/alert-configuration.model'
import { AlertsFacadeBase } from '../strategies/alerts-facade-base'

@Injectable()
export class AlertsFacade extends AlertsFacadeBase {
  override addSuccess(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    throw new Error('Please implement an strategy')
  }
  override addError(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    throw new Error('Please implement an strategy')
  }
  override addInfo(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    throw new Error('Please implement an strategy')
  }
  override addWarning(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void {
    throw new Error('Please implement an strategy')
  }
  override remove(id: string | number): void {
    throw new Error('Please implement an strategy')
  }
}
