import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'

import { Alert } from '../domain/alert.model'
import { AlertType } from '../domain/alerts-legacy.models'
import { AlertConfiguration } from '../domain/alert-configuration.model'

const NAME = '[ALERTS]'

export const ActionType = {
  RESET: `${NAME} RESET`,
  ADD_ALERT: `${NAME} ADD_ALERT`,
  REMOVE_ALERT: `${NAME} REMOVE_ALERT`
}

export const reset = createAction(ActionType.RESET)

export const addAlert = createAction(
  ActionType.ADD_ALERT,
  props<{
    label: string
    alertType: AlertType
    duration?: number
    id?: number | string
    autoClose?: boolean
    displaySpinner?: boolean
    canClose?: boolean
    customClass?: string
  }>()
)

export const removeAlert = createAction(
  ActionType.REMOVE_ALERT,
  props<{ id: number | string }>()
)

export const alertsActions = createActionGroup({
  source: 'Alerts',
  events: {
    add: props<{ alert: Alert; configuration: AlertConfiguration }>(),
    remove: props<{ id: Alert['id'] }>(),
    reset: emptyProps()
  }
})
