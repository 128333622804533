import * as fromAlerts from './alerts.reducer'
import { createSelector, createFeatureSelector } from '@ngrx/store'

export const selectAlertEntity =
  createFeatureSelector<fromAlerts.State>('alerts')

export const getAlerts = createSelector(selectAlertEntity, fromAlerts.alerts)
export const selectAlertsState = createFeatureSelector<fromAlerts.State>(
  fromAlerts.ALERTS_FEATURE_KEY
)

export const selectAlerts = createSelector(
  selectAlertsState,
  (state: fromAlerts.State) => state.alerts
)
