import { Injectable, inject } from '@angular/core'
import { Store } from '@ngrx/store'

import { addAlert, removeAlert } from './alerts.actions'

import { AlertType } from '../domain/alerts-legacy.models'

/**
 * @deprecated
 * Use AlertsFacade with an Strategy instead
 */
@Injectable()
export class LegacyAlertsFacade {
  private readonly store = inject(Store)

  addAlert(props: {
    label: string
    alertType: AlertType
    duration?: number
    id?: number
    autoClose?: boolean
    displaySpinner?: boolean
    canClose?: boolean
  }) {
    if (props?.alertType === "danger"){
      console.error("Toast error", props?.label, new Error().stack)
    }
    this.store.dispatch(addAlert(props))
  }

  removeAlert(props: { id: number }) {
    this.store.dispatch(removeAlert(props))
  }
}
