import { Alert } from '../domain/alert.model'
import { AlertParam } from '../domain/alert-param.model'
import { AlertConfiguration } from '../domain/alert-configuration.model'
import { Store } from '@ngrx/store'
import { inject } from '@angular/core'
import { getAlerts, selectAlerts } from '../+state/alerts.selectors'

export abstract class AlertsFacadeBase {
  protected readonly store = inject(Store)
  legacyAlerts$ = this.store.select(getAlerts)
  alerts$ = this.store.select(selectAlerts)

  abstract addSuccess(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void

  abstract addError(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void

  abstract addInfo(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void

  abstract addWarning(
    alert: AlertParam,
    extraConfiguration?: AlertConfiguration
  ): void

  abstract remove(id: Alert['id']): void
}
