import { createFeature, createReducer, on } from '@ngrx/store'

import * as fromAlerts from './alerts.actions'
import { LegacyAlert } from '../domain/alerts-legacy.models'
import { AlertToast } from '../domain/alert.model'
import { alertsActions } from './alerts.actions'
import { KnownAlertProps } from '../domain/known-alert-props'

export const ALERTS_FEATURE_KEY = 'feature-alerts'

export interface State {
  legacyAlerts: LegacyAlert[]
  alerts: AlertToast[]
}

export const initialState: State = {
  alerts: [],
  legacyAlerts: []
}

export const alertsReducer = createReducer(
  initialState,
  on(fromAlerts.reset, _ => ({ ...initialState })),
  on(
    fromAlerts.addAlert,
    (
      state,
      {
        label,
        alertType,
        duration,
        id,
        autoClose,
        displaySpinner,
        canClose,
        customClass
      }
    ) => ({
      ...state,
      alerts: [
        ...state.alerts,
        new LegacyAlert({
          label,
          alertType,
          duration,
          id,
          autoClose,
          displaySpinner,
          canClose,
          customClass
        }).toNewAlert()
      ]
    })
  ),
  on(fromAlerts.removeAlert, (state, { id }) => ({
    ...state,
    alerts: [...state.alerts].filter(x => x.id !== id)
  })),
  on(alertsActions.add, (state, { alert, configuration }) => ({
    ...state,
    alerts: [
      ...state.alerts,
      { ...alert, ...configuration, ...KnownAlertProps[alert.type] }
    ]
  })),
  on(alertsActions.remove, (state, { id }) => {
    console.log(
      'remove',
      id,
      [...state.alerts].filter(x => x.id !== id)
    )
    return {
      ...state,
      alerts: [...state.alerts].filter(x => x.id !== id)
    }
  }),
  on(alertsActions.reset, _ => ({ ...initialState }))
)

export const alertsFeature = createFeature({
  name: ALERTS_FEATURE_KEY,
  reducer: alertsReducer
})

export const alerts = (state: State) => state.legacyAlerts
