import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core'
import { provideState } from '@ngrx/store'
import { AlertsFacade } from '../+state/alerts.facade'
import { alertsFeature } from '../+state/alerts.reducer'
import { LegacyAlertsFacadeStrategy } from '../strategies'
import { LegacyAlertsFacade } from '../+state/legacy-alerts.facade'
// import { provideEffects } from '@ngrx/effects'
// import { AlertsEffects } from '../+state/alerts.effects'
// import { provideAnimations } from '@angular/platform-browser/animations'
// import { provideToastr } from 'ngx-toastr'

export function provideAlertsFeature(): EnvironmentProviders {
  return makeEnvironmentProviders([
    // provideAnimations(), // required animations providers
    // provideToastr({
    //   timeOut: 0,
    //   disableTimeOut: true,
    //   positionClass: 'toast-bottom-right',
    //   preventDuplicates: true,
    //   closeButton: true,
    //   countDuplicates: true,
    //   progressBar: true
    // }),
    // provideEffects(AlertsEffects),
    provideState(alertsFeature),
    { provide: AlertsFacade, useClass: LegacyAlertsFacadeStrategy },
    LegacyAlertsFacade
  ])
}
